(function(window, document, undefined) {
'use strict';
/// here is where the dragon lives

$(document).ready(function () {
    $('.fancybox').fancybox({
        helpers : {
            title : {
                type : 'inside'
            }
        }
    });
});

$('.youtube-hint-button').click(function (e) {
    e.stopPropagation();

    var youtubeContainer = $(this).closest('.youtube-container');
    youtubeContainer.removeClass('disabled');

    var img = youtubeContainer.find('img');
    img.hide();

    var frame = youtubeContainer.find('iframe');
    frame.attr('src', frame.data('src'));
    frame.show();
});

})(window, document);
